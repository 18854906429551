import {
  footerCert1,
  footerCert2,
  footerCert3,
  footerCert4,
  footerCert5,
  footerFb,
  footerFbHover,
  footerInsta,
  footerInstaHover,
  footerMessangerHover,
  footerMessenger,
  footerTelegram,
  footerTelegramHover,
  footerWhatsapp,
  footerWhatsappHover,
  footerYoutube,
  footerYoutubeHover,
} from '../../images';

export const certificationList = [
  {
    title: 'cert1',
    icon: footerCert1,
    w: 67,
    h: 43,
  },
  {
    title: 'cert2',
    icon: footerCert2,
    w: 67,
    h: 43,
  },
  {
    title: 'cert3',
    icon: footerCert3,
    w: 67,
    h: 43,
  },
  {
    title: 'cert4',
    icon: footerCert4,
    w: 67,
    h: 43,
  },
  {
    title: 'cert5',
    icon: footerCert5,
    w: 67,
    h: 43,
  },
];

export const followUsList = [
  {
    title: 'followusFb',
    icon: footerFb,
    hoverIcon: footerFbHover,
    link: 'https://www.facebook.com/people/Betleon77/100094343021849/',
  },
  {
    title: 'followUsInsta',
    icon: footerInsta,
    hoverIcon: footerInstaHover,
    link: 'https://www.instagram.com/bleon77/',
  },
  {
    title: 'followUsYoutube',
    icon: footerYoutube,
    hoverIcon: footerYoutubeHover,
    link: 'https://www.youtube.com/@bleon77',
  },
  {
    title: 'serviceTelegram',
    icon: footerTelegram,
    hoverIcon: footerTelegramHover,
    link: 'https://t.me/Betleon77',
  },
];

export const memberServiceList = [
  {
    title: 'serviceMessenger',
    icon: footerMessenger,
    hoverIcon: footerMessangerHover,
    link: 'https://m.me/171686696028588',
  },
  // {
  //   title: 'serviceWhatsapp',
  //   icon: footerWhatsapp,
  //   link: 'https://wa.link/sgh5q8',
  // },
];
