import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { SettingActions } from '../reducers/settingReducer';
import { followUsList, memberServiceList } from '../constant/newFooterContent';
import {
  logoIcon,
} from '../../images';

function Footer({
  setContactShow,
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const activeSelected = useSelector((state) => state.setting.selected);
  const isMobile = useSelector((state) => state.setting.isMobile);
  const [isHovered, setIsHovered] = useState('');

  const footerMenuRow2 = [
    {
      key: 11,
      label: 'Contact Us',
      type: '',
      action: () => {
        dispatch(SettingActions.setSelected(11));
        if (router.pathname === '/contact-us') {
          setContactShow(true);
        } else {
          dispatch(SettingActions.setLoading(true));
          router.push('/contact-us');
        }
      },
    },
    {
      key: 10,
      label: 'Privacy policy',
      type: '',
      url: '/privacy-policy.pdf',
    },
    {
      key: 12,
      label: 'Responsible Gaming',
      type: '',
      action: () => {
        router.push('/responsible-gaming');
      },
    },
  ];

  return (
    <div
      className={` ${router.pathname === '/game_website' && 'hidden'}`}
    >
      <div className='bg-white pt-5 md:pt-10 pb-20 md:m-auto md:text-center px-4'>
        <Image alt='BLeon77' src={logoIcon} width={128} height={45} placeholder='empty' />

        <div className='grid grid-cols-1 gap-4 border-b pt-8 pb-5 flex justify-evenly'>
          <div className='menu-responsive flex flex-col md:flex-row bg-white gap-10 justify-center'>
            {footerMenuRow2.map((i, index) => {
              const isActive = activeSelected === i.key;
              if (i.action) {
                return (
                  <div
                    className={`opacity-50 text-xs font-normal capitalize cursor-pointer ${isActive && 'active'}`}
                    key={i.key}
                    onClick={(e) => i.action()}
                  >
                    {i.label}
                  </div>
                );
              }
              return (
                <a
                  key={i.key}
                  href={i.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div
                    className={`opacity-50 text-xs font-normal capitalize cursor-pointer ${isActive && 'active'}`}
                  >
                    {i.label}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <div className='grid grid-cols-2 gap-6 py-6'>
          <div className='md:text-end md:flex items-center justify-end'>
            <p className='opacity-50 font-normal text-xs mb-2 md:mb-0'>Follow Us</p>
            <div className='flex items-center align-center'>
              {followUsList.map((item, index) => (
                <Link href={item.link} key={index} passHref>
                  <a
                    className='group relative inline-block cursor-pointer md:ml-2 mr-1 md:mr-0'
                    onMouseEnter={() => setIsHovered(item.title)}
                    onMouseLeave={() => setIsHovered('')}
                  >
                    {isHovered === item.title
                      ? (
                        <Image

                          src={item.hoverIcon}
                          alt={item.title}
                          width={isMobile ? 35 : 40}
                          height={isMobile ? 35 : 40}
                          placeholder='empty'
                        />
                      )
                      : (
                        <Image

                          src={item.icon}
                          alt={`${item.title}`}
                          width={isMobile ? 35 : 40}
                          height={isMobile ? 35 : 40}
                          placeholder='empty'
                        />
                      )}
                  </a>
                </Link>
              ))}
            </div>
          </div>
          <div className='text-start md:flex items-center justify-start'>
            <p className='opacity-50 font-normal text-xs mb-2 md:mb-0'>Member Service</p>
            <div className='flex items-center align-center'>
              {memberServiceList.map((item, index) => (
                <Link href={item.link} key={index} passHref>
                  <a
                    className='group relative inline-block cursor-pointer md:ml-2 mr-1 md:mr-0'
                    onMouseEnter={() => setIsHovered(item.title)}
                    onMouseLeave={() => setIsHovered('')}
                  >
                    {isHovered === item.title
                      ? (
                        <Image

                          src={item.hoverIcon}
                          alt={item.title}
                          width={isMobile ? 35 : 40}
                          height={isMobile ? 35 : 40}
                          placeholder='empty'
                        />
                      )
                      : (
                        <Image

                          src={item.icon}
                          alt={`${item.title}`}
                          width={isMobile ? 35 : 40}
                          height={isMobile ? 35 : 40}
                          placeholder='empty'
                        />
                      )}
                  </a>
                </Link>
              ))}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Footer;
