import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import {
  NavbarCasino,
  NavbarMain,
  NavbarMenu,
  NavbarSports,
  NavbarHistory,
  NavbarReferral,
} from '../../images';
import { getGameCred } from '../../api/getGameCred';
import { getBrand } from '../../api/getBrand';

import { SettingActions } from '../reducers/settingReducer';

function NavbarBottom({
  show,
  toggleModal,
  setShowModal,
  toggleShowCustomLiveChatModal,
  setShow,
}) {
  const mobileMenuSelected = useSelector(
    (state) => state.setting.mobileMenuSelected,
  );
  const router = useRouter();
  const dispatch = useDispatch();

  const accessToSport = async (hasPath, demo) => {
    const brandInfo = await getBrand(process.env.SPORT_ID);
    const brandId = brandInfo?.data?._id;
    const gameId = brandInfo?.data?.games?._id;
    if (demo) {
      router.push(`/game_website?brandId=${'658a2e8decc17606d6055c36'}${hasPath ? `&toPath=${hasPath}` : ''}&demo=${demo}`);
    } else {
      const gameInfo = await getGameCred({ brandId: process.env.SPORT_ID });

      if (gameInfo?.error) {
        if (!gameInfo?.error.includes('jwt')) {
          dispatch(
            SettingActions.setAlert([
              {
                msg: gameInfo?.error,
              },
            ]),
          );
        }
        return false;
      }

      const gameProfileId = gameInfo?.data?.data?.[0]._id;
      router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}${hasPath ? `&toPath=${hasPath}` : ''}&demo=${demo}`);
    }
  };

  const menu = [
    {
      name: 'Main',
      className: 'col-span-2',
      img: NavbarMain,
      action: (i) => {
        dispatch(SettingActions.setActiveProviderSelected(null));
        dispatch(SettingActions.setMobileMenuSelected(0));
        router.push('/');
      },
    },
    {
      name: 'Sports',
      className: 'col-span-2',
      img: NavbarSports,
      action: async () => {
        if (Cookies.get('accessToken')) {
          accessToSport();
          dispatch(SettingActions.setMobileMenuSelected(1));
        } else {
          accessToSport('', true);
        }
      },
    },
    {
      name: 'History',
      className: 'col-span-2',
      img: NavbarHistory,
      action: () => {
        if (Cookies.get('accessToken')) {
          dispatch(SettingActions.setSelected(99));
          dispatch(SettingActions.setMobileMenuSelected(2));
          router.push('/history');
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      name: 'Casino',
      className: 'col-span-2',
      img: NavbarCasino,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        dispatch(SettingActions.setSelected(3));
        dispatch(SettingActions.setMobileMenuSelected(3));

        router.push({
          pathname: '/casino-main-page',
        });
      },
    },
    {
      name: 'Referral',
      className: 'col-span-2',
      img: NavbarReferral,
      action: (i) => {
        if (!Cookies.get('accessToken')) {
          dispatch(SettingActions.setLoginShow(true));
        } else {
          dispatch(SettingActions.setMobileMenuSelected(4));
          router.push({
            pathname: '/referral',
          });
        }
      },
    },
    {
      name: 'Menu',
      className: 'col-span-2',
      img: NavbarMenu,
      action: (i) => {
        setShow(true);
        setShowModal(false);
        dispatch(SettingActions.setMobileMenuSelected(i));
      },
    },
  ];

  return (
    <div className='navbar-bottom block md:hidden'>
      <div className='navbar-child grid grid-cols-12 items-end pb-1'>
        {menu.map((x, i) => (
          <div
            key={i}
            className={`${mobileMenuSelected === i ? 'text-center active' : 'text-center'
            } ${x.className}`}
            onClick={() => {
              if (x.action) {
                x.action(i);
              } else {
                setShowModal(false);
                dispatch(SettingActions.setMobileMenuSelected(i));
                // dispatch(SettingActions.setLoading(true));
                router.push(x.link);
              }
            }}
          >
            <div className='leading-none'>
              <Image src={x.img} alt={x.name} placeholder='empty' />
            </div>
            {x.name && <p>{x.name}</p>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default NavbarBottom;
