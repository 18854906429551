import React, { useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import {
  HelpCenter,
  sidebarLiveChatIcon,
  sidebarEmailIcon,
  sidebarMessengerIcon,
  sidebarFacebookIcon,
  sidebarTelegramIcon,
  sidebarHelpIcon,
  HelpCenterMobile,
  sidebarLiveChatIconHover,
  sidebarEmailIconHover,
  sidebarMessengerIconHover,
  sidebarFacebookIconHover,
  sidebarTelegramIconHover,
  sidebarHelpIconHover,
} from '../../images';

function LiveChat(props) {
  const router = useRouter();
  const isMobile = useSelector((state) => state.setting.isMobile);
  const [helpCenterActive, setHelpCenterActive] = useState(0);
  const [mobileToggle, setMobileToggle] = useState(false);

  const sidebarContent = [
    {
      icon: sidebarLiveChatIcon,
      iconEvent: sidebarLiveChatIconHover,
      text: '24/7 Live Chat',
      action: () => {
        props.setShowCustomLiveChatModal(!props.showCustomLiveChatModal);
      },
    },
    {
      icon: sidebarEmailIcon,
      iconEvent: sidebarEmailIconHover,
      text: 'usersupport@betleon77.com',
      action: () => {
        window.open('mailto:usersupport@betleon77.com');
      },
    },
    {
      icon: sidebarMessengerIcon,
      iconEvent: sidebarMessengerIconHover,
      text: 'Bleon77 Support',
      action: () => {
        window.open('https://m.me/171686696028588', '_blank');
      },
    },
    {
      icon: sidebarFacebookIcon,
      iconEvent: sidebarFacebookIconHover,
      text: 'Bleon77',
      action: () => {
        window.open('https://www.facebook.com/people/Betleon77/100094343021849/', '_blank');
      },
    },
    {
      icon: sidebarTelegramIcon,
      iconEvent: sidebarTelegramIconHover,
      text: 'Telegram',
      action: () => {
        window.open('https://t.me/Betleon77', '_blank');
      },
    },
    {
      icon: sidebarHelpIcon,
      iconEvent: sidebarHelpIconHover,
      text: 'Help & FAQs',
      action: () => {
        router.push('/faq');
      },
    },
  ];

  return (
    <div
      className={`font-semibold z-50 cursor-pointer help-center-wrapper ${router.pathname !== '/game_website' ? 'fixed' : 'hidden'}`}
      style={isMobile ? {
        top: '25rem',
        right: mobileToggle && 0,
      } : {
        top: '25rem',
      }}
    >
      {
        isMobile && mobileToggle
        && (
          <div
            className='help-center-overlay-closeable'
            onClick={() => setMobileToggle(!mobileToggle)}
          />
        )
      }
      <div className='flex z-10 relative'>
        <div
          className='inline-block cursor-pointer h-full relative '
          onClick={() => setMobileToggle(!mobileToggle)}
        >
          <Image
            width={isMobile ? 42 : 52}
            height={isMobile ? 168 : 209}
            src={isMobile ? HelpCenterMobile.src : HelpCenter.src}
            alt=''
            placeholder='empty'
          />
        </div>

        <div
          className='bg-white border-b-2 border-t-2 mt-1.5 md:mt-2 -mb-4 md:mb-7 border-l-2 md:border-l-0  flex flex-col justify-between '
          style={{
            borderColor: 'rgba(40,174,96)',
          }}
        >
          {sidebarContent.map((item, index) => (
            <div
              key={index}
              className='flex cursor-pointer items-center border-b last:border-none pb-1 hover:bg-[#28AE60] px-1 md:px-3 pt-1'
              onClick={item.action}
              onMouseEnter={() => setHelpCenterActive(index)}
              onMouseLeave={() => setHelpCenterActive(-1)}
            >
              <div className='leading-none mr-2'>
                {
                  helpCenterActive === index
                    ? (
                      <Image

                        src={item.iconEvent}
                        alt={item.text}
                        width={isMobile ? 16 : 22}
                        height={isMobile ? 16 : 22}
                        placeholder='empty'
                      />
                    )
                    : (
                      <Image

                        src={item.icon}
                        alt={item.text}
                        width={isMobile ? 16 : 22}
                        height={isMobile ? 16 : 22}
                        placeholder='empty'
                      />
                    )
                }

                {/* <Image
                  src={item.icon}
                  alt={item.text}
                  width={isMobile ? 16 : 22}
                  height={isMobile ? 16 : 22}
                /> */}
              </div>
              <div className={`text-3xs md:text-2xs font-normal ${helpCenterActive === index && 'text-white'}`}>{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LiveChat;
